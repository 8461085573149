import * as React from "react"

import { graphql } from "gatsby"
import Header from "components/Header"
import Heading from "components/Heading"
import Footer from "components/Footer"
import Button from "components/Button"

import { generalSection,
    generalHeading,
    generalBody,
    generalBodyText,
    generalBodyImg,
    generalBodySection
} from './archive-blog.module.css'

const ArchiveBlog = ({ pageContext, data }) => {
    const { allShopifyArticle, shopifyBlog } = data
    const generalHeadingData = shopifyBlog;
    const generalContentData = allShopifyArticle.edges

    const cmsBlogUrl = generalHeadingData.url;

    let generalContentJSX = generalContentData.map(({ node }) => {
        return (
            <div className={generalBodySection} key={ node.id }>
                <div className={generalBodyText}>
                    <div>
                        <Heading level="h3">{node.title}</Heading>
                        <p>{node.excerpt}</p>
                        <Button to={"/news" + node.url.replace(cmsBlogUrl, "")}>Read More</Button>
                    </div>
                </div>
                
                <div className={generalBodyImg}>
                    <img  
                        src={node.image.src}
                        alt={node.image.altText}
                    />
                </div>
            </div>
        );
    });

    return (
    <main>
        <Header />

        <section className={generalSection}>
            <div className={generalHeading}>
                <div>
                    <div>
                        <Heading level="h2">{generalHeadingData.title}</Heading>
                    </div>
                    <div>
                        <p>{generalHeadingData.internal.content}</p>
                    </div>
                </div>
            </div>

            <div className={generalBody}>
                {generalContentJSX}
            </div>
        </section>
        <Footer />
    </main>
    )
}

export default ArchiveBlog

export const query = graphql`
    query ArchiveBlogQuery {
        allShopifyArticle {
            edges {
                node {
                    url
                    excerpt
                    id
                    title
                    image {
                        src
                        altText
                    }
                }
            }
        }
        shopifyBlog {
            url
            title
            internal {
                content
            }
        }
    }
    `